import { useAuth } from '@sharelo-lib/auth';
import Head from 'next/head';
import router from 'next/router';
import React, { useEffect } from 'react';

export default function Home() {
  const { isLoggedIn, afterLoggedIn, currentUser, loaded } = useAuth();

  useEffect(() => {
    if (loaded) {
      if (isLoggedIn && currentUser) {
        afterLoggedIn(currentUser);
      } else {
        router.replace('/login');
      }
    }
  }, [afterLoggedIn, currentUser, isLoggedIn, loaded]);

  return (
    <>
      <Head>
        <title>Sharelo</title>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
    </>
  );
}
